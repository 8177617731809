/// Privacy Policy page for Startbase Management

import { Button, Container, Divider, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SmallLogo } from "../ui/logo";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export default function PrivacyPolicy() {

    return (
        <div className="pt-4">

            <header className="pl-4">
                <Button 
                    onClick={() => window.location.href = "/"}
                >
                    <ArrowBackIosIcon /> Back
                </Button>
            </header>
    
            {/* Logo Container */}
            <div className="m-auto w-fit flex items-center"> 
                  <SmallLogo />
                  <h1 className="text-5xl font-semibold ml-4">
                      Startbase <br /> Management
                  </h1>
            </div>

            <Container maxWidth="md" className="py-10">
                {/* Header */}
                <Typography variant="h3" component="h1" className="text-center font-bold mb-6">
                    Privacy Policy
                </Typography>

                {/* Last Updated */}
                <Typography variant="subtitle1" className="text-gray-600 text-center mb-8" sx={{marginBottom: "2rem"}}>
                    Last Updated: January 23, 2025
                </Typography>

                {/* Introduction */}
                <Typography variant="body1" className="mb-6">
                    Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your
                    information when you use Startbase Management. Please read this policy carefully. 
                </Typography>

                <Divider sx={{marginY: "1.5rem"}}/>

                {/* Information We Collect */}
                <Typography variant="h5" component="h2" className="font-bold mb-4" sx={{marginBottom: "1rem"}}>
                    1. Information We Collect
                </Typography>
                <Typography variant="body1" className="mb-6">
                    We may store personal information that you voluntarily provide to us when you register for an account, express an
                    interest in obtaining information about us or our products and services, or otherwise contact us. Additionally, if 
                    you choose to connect your Google Account to access Startbase's advertising analysis features, we may collect additional 
                    information in order to provide those services. The personal information we collect may include:
                </Typography>
                <ul className="list-disc pl-8 mb-6">
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Phone Number</li>
                    <li>Advertising campaign performance statistics</li>
                    <li>Advertising campaign demographic data</li>
                </ul>

                <Typography>
                    We may also collect personal information about your customers that you provide to us when utilising the booking 
                    management services of Startbase. Collected information may include:
                </Typography>
                <ul className="list-disc pl-8 mb-6">
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Phone Number</li>
                </ul>

                {/* How We Store and Protect Your Information */}
                <Typography variant="h5" component="h2" className="font-bold mb-4" sx={{marginBottom: "1rem"}}>
                    2. How We Store and Protect Your Information
                </Typography>
                <Typography variant="body1" className="mb-6">
                    We employ the following techniques to ensure safe data storage and transmission.
                </Typography>
                <ul className="list-disc pl-8 mb-6">
                    <li>Industry standard encryption for all data storage and transmission</li>
                    <li>Strict data access control with multi-factor authentication</li>
                    <li>Security contacts for monitoring and access logging</li>
                </ul>
                <Typography variant="h6" sx={{marginBottom: "0.5rem"}}>
                    Google-specific Data Retention and Deletion
                </Typography>
                <Typography variant="body1" sx={{marginBottom: "1.5rem"}}>
                    Upon connecting your Google account to access the Google Ads campaign analytics features of Startbase, we store related information 
                    in order to provide analysis services. If you disconnect your Google account, we immediately remove all Google advertising related
                    data.
                </Typography>

                {/* How We Use Your Information */}
                <Typography variant="h5" component="h2" className="font-bold mb-4" sx={{marginBottom: "1rem"}}>
                    3. How We Use Your Information
                </Typography>
                <Typography variant="body1" className="mb-6">
                    We use the information we collect or receive:
                </Typography>
                <ul className="list-disc pl-8 mb-6">
                    <li>To allow you to log your business' bookings</li>
                    <li>To enable automated SMS messaging and emails to you and your customers</li>
                    <li>To analyze and provide insights into you advertising campaigns</li>
                </ul>

                {/* Disclosure of Your Information */}
                <Typography variant="h5" component="h2" className="font-bold mb-4" sx={{marginBottom: "1rem"}}>
                    4. Disclosure of Your Information
                </Typography>
                <Typography variant="body1" className="mb-6">
                    We may share information we have collected about you in certain situations, such as:
                </Typography>
                <ul className="list-disc pl-8 mb-6">
                    <li>With your consent.</li>
                    <li>For business transfers, such as during a merger or acquisition.</li>
                    <li>To comply with legal obligations.</li>
                </ul>



                {/* Contact Us */}
                {/*
                <Typography variant="h5" component="h2" className="font-bold mb-4" sx={{marginBottom: "1rem"}}>
                    4. Contact Us
                </Typography>
                <Typography variant="body1" className="mb-6">
                    If you have questions or comments about this Privacy Policy, please contact us at:
                </Typography>
                <Typography variant="body1" className="mb-6">
                    <Link href="mailto:privacy@example.com" color="primary">
                        privacy@example.com
                    </Link>
                </Typography>
                */}
                <Divider sx={{marginY: "1.5rem"}}/>

            </Container>
          	{/* Footer */}
          	<footer className="bg-black text-white text-sm p-12 w-screen">
          		<div className="flex flex-row justify-center">
          			<span className="ml-2">©2025 Startbase Management | All rights reserved</span>
          		</div>
            </footer>
        </div>
    );
}

